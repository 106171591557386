import { User } from "@prisma/client";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { ApiCo2OffsettingReturnValue } from "../../pages/api/co2offsetting";
import { SORT_QUERY_PREFIX } from "../util";

import { CodeProjectUserRelation, UserApi } from "../util/prismaHelper";
import {
  PaginatedQuery,
  PaginatedResponse,
  SortQuery,
} from "../util/utilServer";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: "/api/",
});

const api = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [
    "Co2Offsetting",
    "CodeProjectRelation",
    "ProjectList",
    "Settings",
    "User",
    "User.Me",
  ],
});

export const ResetApi = api.util.resetApiState;

const statApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSettings: build.query<any, void>({
      query: () => ({
        url: `settings`,
        method: "GET",
      }),
      providesTags: ["Settings"],
    }),
    updateSettings: build.mutation<any, Partial<any>>({
      query: (settings) => ({
        url: `settings`,
        method: "PUT",
        body: settings,
      }),
      invalidatesTags: ["Settings"],
    }),
    getUser: build.query<UserApi, string>({
      query: (id) => ({ url: `/user/${id}`, method: "GET" }),
      providesTags: ["User"],
    }),
    getCurrentUser: build.query<UserApi, void>({
      query: () => ({ url: "/user/me", method: "GET" }),
      providesTags: ["User.Me"],
    }),
    updateUser: build.mutation<UserApi, FormData | Partial<User>>({
      query: ({ ...args }) => ({
        url: `/user`,
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["User", "User.Me"],
    }),
    addCo2Code: build.mutation<
      { success: boolean; treeIds: number[] },
      { code: string }
    >({
      query: (arg) => ({
        url: "/code",
        method: "post",
        body: arg,
      }),
      invalidatesTags: ["Co2Offsetting"],
    }),
    getCo2Offsetting: build.query<
      PaginatedResponse<ApiCo2OffsettingReturnValue>,
      | PaginatedQuery["page"]
      | (PaginatedQuery & { tf?: string; sort?: SortQuery })
    >({
      query: (page) => {
        const q = new URLSearchParams();
        if (typeof page === "number") {
          q.set("page", page.toString());
        } else if (typeof page === "object") {
          if (typeof page.page === "number")
            q.set("page", page.page.toString());
          if (page.tf) q.set("tf", page.tf);
          if (page.sort) {
            Object.entries(page.sort)
              .filter(([_, v]) => v != null)
              .forEach(([key, value]) =>
                q.set(SORT_QUERY_PREFIX + key, value ?? "")
              );
          }
        }

        return {
          url: `/co2offsetting?${q.toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Co2Offsetting"],
    }),
    getPublicCo2Offsetting: build.query<
      PaginatedResponse<ApiCo2OffsettingReturnValue>,
      PaginatedQuery & { tf?: string; sort?: SortQuery; pubId: string }
    >({
      query: (page) => {
        const q = new URLSearchParams();
        if (typeof page.page === "number") q.set("page", page.page.toString());
        if (page.tf) q.set("tf", page.tf);
        if (page.sort) {
          Object.entries(page.sort)
            .filter(([_, v]) => v != null)
            .forEach(([key, value]) =>
              q.set(SORT_QUERY_PREFIX + key, value ?? "")
            );
        }

        return {
          url: `/co2offsetting/pub/${page.pubId}?${q.toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Co2Offsetting"],
    }),
    addProject: build.mutation<
      { success: true; projectId: string } | { error: string },
      FormData
    >({
      query: (treeData) => ({
        url: `/project`,
        method: "POST",
        body: treeData,
      }),
      invalidatesTags: ["ProjectList"],
    }),
    deleteGAConnection: build.mutation<void, void>({
      query: () => ({
        url: `/connection`,
        method: "DELETE",
      }),
      invalidatesTags: ["User"],
    }),
    listCodeProjectRelations: build.query<
      PaginatedResponse<CodeProjectUserRelation[]>,
      PaginatedQuery & { type: "nft" | "project"; id: string }
    >({
      query: ({ id, page = 1, type }) => ({
        url:
          (type === "nft" ? "nft/" : "project/") + id + "/codes?page=" + page,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [
        { type: "CodeProjectRelation", id: arg.id },
      ],
    }),
  }),
});

export const ApiReducer = api.reducer,
  ApiMiddleware = api.middleware,
  ApiReducerPath = api.reducerPath;
export const {
  useGetCurrentUserQuery,
  useGetCo2OffsettingQuery,
  useGetPublicCo2OffsettingQuery,
  useAddCo2CodeMutation,
  useUpdateUserMutation,
  useAddProjectMutation,
  useDeleteGAConnectionMutation,
  useListCodeProjectRelationsQuery,
} = statApi;
export const _statApi = statApi;
